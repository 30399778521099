import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function AnimeCard({ japTitleRomaji, engTitle, season, year, cover, myScore, isFirstImpression }) {
  const coverArt = getImage(cover)

  return (
    <div className="flex flex-nowrap items-center rounded-md drop-shadow-md bg-stone-50 hover:bg-gradient-to-b from-stone-400 to-stone-500 text-stone-700 hover:text-stone-50">
      <div className="w-24 md:w-36 h-auto shrink-0">
        <GatsbyImage image={coverArt} alt={`${japTitleRomaji} cover art`} className="rounded-l-md" />
      </div>
      <div className="px-2 md:px-4 font-semibold">
        <p className="pb-1 md:pb-2 text-sm md:text-base">
          {season} {year} {isFirstImpression ? <span className="mx-0.5 px-2 py-0.5 md:mx-2 md:py-1 rounded-full bg-lime-600 text-lime-50">First Impression</span> : ``}
        </p>
        <p className="pb-0.5 md:pb-1 font-bold text-base md:text-2xl leading-tight line-clamp-2">
          {japTitleRomaji}
        </p>
        <p className="text-base md:text-lg leading-tight line-clamp-1 opacity-60">
          {engTitle}
        </p>
        {
          myScore ?
            <p className="pt-1 md:pt-2 text-xs md:text-sm">
              Personal score: {myScore}/10
            </p>
            : ``
        }
      </div>
    </div>
  )
}