import React, { useState } from 'react'
import Layout from '../components/Layout'
import { SEO } from '../components/SEO'
import { graphql, Script } from 'gatsby'
import AnimeCard from '../components/AnimeCard'
import AdsBanner from '../components/AdsBanner'

export default function Home({ data }) {
  const animes = data.allMarkdownRemark.nodes

  const [searchedAnimes, setSearchedAnimes] = useState(animes)

  const HandleSearch = (term) => {
    if (term === "") {
      setSearchedAnimes(animes)
      return
    }
    const filteredAnime = animes.filter(anime =>
      anime.frontmatter.japTitleRomaji.toLowerCase().includes(term.toLowerCase())
      ||
      anime.frontmatter.engTitle.toLowerCase().includes(term.toLowerCase())
    )
    setSearchedAnimes(filteredAnime)
  }

  return (
    <Layout>
      <section className="font-sans text-stone-700 text-center">
        <h1 className="pt-4 md:pt-8 font-bold text-2xl md:text-3xl">
          Should I Watch This Anime?
        </h1>
        <h2 className="pt-2 md:pt-4 font-bold text-base md:text-lg">
          Find out if you should watch a particular anime in five minutes or less.
        </h2>
      </section>

      <section id="search-bar" class="py-4 md:py-8">
        <div class="container mx-auto max-w-xl">
          <input type="text" placeholder="Search your particular anime"
            class="w-full rounded-md p-2 md:p-4 font-semibold text-base md:text-lg drop-shadow-md bg-stone-50"
            onChange={e => HandleSearch(e.target.value)} />
        </div>
      </section>

      <section className="pb-6 md:pb-10 font-sans max-w-4xl mx-auto">

        {
          searchedAnimes.length > 0 ?
            searchedAnimes.map((anime, index) => {
              return (
                <div key={anime.id} className="my-4 md:my-6">
                  <a href={"/review/" + anime.frontmatter.slug}>
                    <AnimeCard
                      japTitleRomaji={anime.frontmatter.japTitleRomaji}
                      engTitle={anime.frontmatter.engTitle}
                      season={anime.frontmatter.season}
                      year={anime.frontmatter.year}
                      myScore={anime.frontmatter.myScore}
                      isFirstImpression={anime.frontmatter.firstImpression}
                      cover={anime.frontmatter.coverArt}
                    />
                  </a>
                </div>
              )
            })
            :
            <div className="my-4 md:my-6 text-center">
              <p>Sorry, can't find your particular anime. Please check for typo or try another title.</p>
            </div>
        }
      </section>

      <section>
        <p className="text-[1px] text-transparent select-none">
          Tags: should i watch this anime, anime review and opinion website, is anime good, anime worth watching, what anime should i watch, anime recommendation site, situs anime review pendapat dan opini, anime apa yang bagus layak ditonton, rekomendasi anime lengkap
        </p>
      </section>
    </Layout >
  )
}

export const query = graphql`
  query Animes {
    allMarkdownRemark(sort: {fields: frontmatter___dateCreated, order: DESC}) {
      nodes {
        frontmatter {
          coverArt {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
          engTitle
          japTitleRomaji
          firstImpression
          myScore
          season
          year
          slug
        }
        id
      }
    }
  }
`

export const Head = () => (
  <>
    <SEO
      title="Should I Watch This Anime? Honest Anime Reviews and Opinions"
      type="website"
    />
    <Script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9850042708275295"
      crossorigin="anonymous"></Script>
  </>
)